@font-face {
  font-family: 'MyFont3';
  src: url("../font/NeueHansKendrick-Regular.otf");
}

.header-line {
  border-bottom: 3px dotted #8b8a8a;
}

.animate-link {
  font-size: large;
  font-family: MyFont3, sans-serif;
  padding-bottom: 2px;
  text-decoration: none; /* デフォルトの下線を非表示にする */
  color: #000000; /* 文字色 */
  background-image: linear-gradient(90deg, #000000, #000000); /* 線の色 */
  background-repeat: no-repeat;
  background-position: left bottom; /* 線の起点を左・下に設定 */
  background-size: 0 2px; /* 線の横幅を0、縦幅を1pxに */
  transition: background-size 0.6s; /* 線を伸ばすアニメーション実行時間を指定 */
}

.animate-link:hover {
  background-size: 100% 2px; /* 線の横幅を100%にする */
}

.blue-section {
  background-size: cover;
  background-image: none;
}

#top-banner-container{
  min-height: 400px;
  line-height: 0;
  height: calc(100vh - 55px);
  background-color: #bbd77e;
}

.banner-container-2{
  min-height: 400px;
  line-height: 0;
  height: 50vh;
  background-image: url("../img/28070256_s.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#banner-container-3{
  min-height: 400px;
  line-height: 0;
  height: auto;
}

#banner-container-4 {
  min-height: 400px;
  line-height: 0;
  height: auto;
  background-color: #b3d4fc;
}

.card-title-2 {
  text-decoration: none; /* デフォルトの下線を非表示にする */
  color: #000000; /* 文字色 */
}
.card-img-top {
  border-radius: var(--bs-card-inner-border-radius);
  height: max(220px);
  width: max(220px);
}

.img-on-txt-area {
  height: max(230px);
  width: max(280px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-on-txt-area:hover .card-img-top{
  border-radius: var(--bs-card-inner-border-radius);
  filter: blur(10px);
}

.img-on-txt-area:hover .img-on-txt{
  animation:alpha 3s;
  animation-fill-mode:forwards;
}

#row-card-1 {
  opacity: 0;
}

.alpha {
  animation:alpha 3s;
  animation-fill-mode:forwards;
}

@keyframes alpha {
  0% {
    opacity:0;
  }
  50%{
    opacity:0.5;
  }
  100% {
    opacity:1;
  }
}


.img-on-txt-area {/*親div*/
  position: relative;/*相対配置*/
}

.img-on-txt{
  position: absolute;/*絶対配置*/
  color: white;/*文字は白に*/
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin: 0;
  padding: 0;
  opacity: 0;
}

.profile-content{
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-css-alpha-1 {
  animation:progress-css-alpha-1 3s;
  animation-fill-mode:forwards;
}

@keyframes progress-css-alpha-1 {
  0% {
    width: 0;
  }
  100% {
    width: 25%;
  }
}
.progress-css-alpha-2 {
  animation:progress-css-alpha-2 3s;
  animation-fill-mode:forwards;
}
@keyframes progress-css-alpha-2 {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}

.progress-css-alpha-3  {
  animation:progress-css-alpha-3 3s;
  animation-fill-mode:forwards;
}
@keyframes progress-css-alpha-3 {
  0% {
    width: 0;
  }
  100% {
    width: 75%;
  }
}

.progress-css-alpha-4  {
  animation:progress-css-alpha-4 3s;
  animation-fill-mode:forwards;
}
@keyframes progress-css-alpha-4 {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
