@font-face {
  font-family: MyFont3;
  src: url("NeueHansKendrick-Regular.25027cfc.otf");
}

.header-line {
  border-bottom: 3px dotted #8b8a8a;
}

.animate-link {
  color: #000;
  background-image: linear-gradient(90deg, #000, #000);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 2px;
  padding-bottom: 2px;
  font-family: MyFont3, sans-serif;
  font-size: large;
  text-decoration: none;
  transition: background-size .6s;
}

.animate-link:hover {
  background-size: 100% 2px;
}

.blue-section {
  background-image: none;
  background-size: cover;
}

#top-banner-container {
  background-color: #bbd77e;
  height: calc(100vh - 55px);
  min-height: 400px;
  line-height: 0;
}

.banner-container-2 {
  background-image: url("28070256_s.37dfd970.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  min-height: 400px;
  line-height: 0;
}

#banner-container-3 {
  height: auto;
  min-height: 400px;
  line-height: 0;
}

#banner-container-4 {
  background-color: #b3d4fc;
  height: auto;
  min-height: 400px;
  line-height: 0;
}

.card-title-2 {
  color: #000;
  text-decoration: none;
}

.card-img-top {
  border-radius: var(--bs-card-inner-border-radius);
  width: 220px;
  height: 220px;
}

.img-on-txt-area {
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 230px;
  display: flex;
}

.img-on-txt-area:hover .card-img-top {
  border-radius: var(--bs-card-inner-border-radius);
  filter: blur(10px);
}

.img-on-txt-area:hover .img-on-txt {
  animation: 3s forwards alpha;
}

#row-card-1 {
  opacity: 0;
}

.alpha {
  animation: 3s forwards alpha;
}

@keyframes alpha {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.img-on-txt-area {
  position: relative;
}

.img-on-txt {
  color: #fff;
  opacity: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.progress-css-alpha-1 {
  animation: 3s forwards progress-css-alpha-1;
}

@keyframes progress-css-alpha-1 {
  0% {
    width: 0;
  }

  100% {
    width: 25%;
  }
}

.progress-css-alpha-2 {
  animation: 3s forwards progress-css-alpha-2;
}

@keyframes progress-css-alpha-2 {
  0% {
    width: 0;
  }

  100% {
    width: 50%;
  }
}

.progress-css-alpha-3 {
  animation: 3s forwards progress-css-alpha-3;
}

@keyframes progress-css-alpha-3 {
  0% {
    width: 0;
  }

  100% {
    width: 75%;
  }
}

.progress-css-alpha-4 {
  animation: 3s forwards progress-css-alpha-4;
}

@keyframes progress-css-alpha-4 {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

/*# sourceMappingURL=index.385099f4.css.map */
